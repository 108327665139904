<template>
  <div>
    <item-list-view
        :api="api"
        :headers="headers"
        id-key="identifier"
        title="Banned identifiers"
        search-placeholder="Search"
        :allowed-create="false"
        :allowed-search="true"
        :allowed-remove="true"
        remove-title="Delete"
        remove-message="Вы действительно хотите удалить '<%= item.title %>'?"
    >
      <template v-slot:item.blockedAt="{ item }">
        {{ item.blockedAt | dateTime }}
      </template>
    </item-list-view>
  </div>
  </template>

  <script>
  import api from '../services/banned-identifiers'
  import ItemListView from '../components/ItemListView'

  export default {
    components: {
      ItemListView,
    },
    data: () => ({
      api: api,
      headers: [
        { text: 'Номер', value: 'identifier' },
        { text: 'Заблокирован', value: 'blockedAt' },
        { text: 'IP адрес', value: 'ip' },
        { text: 'Клиент', value: 'userAgent' },
        { text: '', value: 'action' }
      ],
      statuses: api.statuses
    })
  }
  </script>
