import BaseService from './baseService'

class BannedIndentifiersService extends BaseService {
  get url () {
    return 'banned-identifiers'
  }

  // getAll () {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve({
  //         limit: 5,
  //         total: 5,
  //         page: 1,
  //         pages: 1,
  //         items: [
  //           {
  //             "identifier": "+79283411655",
  //             "blockedAt": "2023-04-04T09:38:03.069Z",
  //             "ip": "879.877.45.23",
  //             "userAgent": "Chrome"
  //           },
  //           {
  //             "identifier": "+79217982387",
  //             "blockedAt": "2022-11-21T12:04:03.069Z",
  //             "ip": "198.117.23.21",
  //             "userAgent": "Firefox"
  //           }
  //         ]
  //       })
  //     }, 500)
  //   })
  // }
}

export default new BannedIndentifiersService()

